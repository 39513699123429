
/**
 * @name: 骑手管理
 * @author: itmobai
 * @date: 2023-07-20 10:02
 * @description：骑手管理
 * @update: 2023-07-20 10:02
 */
import { Vue, Component } from "vue-property-decorator"
import { mallRiderQueryByPageApi, mallRiderCreateApi, mallRiderModifyApi, mallRiderDetailApi } from "@/apis/site/rider"
import { mallSiteQueryByListApi } from "@/apis/site/list"
import { ISite } from "@/apis/site/list/types"
import { IRider, IRiderParam } from "@/apis/site/rider/types"
import { ICrudOption } from "@/types/m-ui-crud"
import { checkPhone } from "@/constants/validators"

@Component({})
export default class RiderPage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: IRider[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: IRiderParam = {
    page: 1,
    limit: 10
  }
  // 表单数据
  modelForm: Partial<IRider> = {}
  // 配置信息
  crudOption: ICrudOption = {
    labelWidth: '80px',
    dialogWidth: '600px',
    addTitle: '新增骑手',
    editTitle: '编辑骑手',
    column: [
      {
        label: "ID",
        prop: "id",
        align: "left",
        width: 180,
        editHide: true,
        addHide: true
      },
      {
        label: "姓名",
        prop: "riderKeywords",
        hide: true,
        editHide: true,
        addHide: true,
        search: true,
        placeholder: "输入姓名/手机号模糊搜索"
      },
      {
        label: "姓名",
        prop: "riderName",
        overHidden: true,
        maxlength: 20,
        span: 24,
        rules: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ]
      },
      {
        label: "手机号",
        prop: "phone",
        align: "center",
        width: 180,
        maxlength: 11,
        span: 24,
        rules: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {validator: checkPhone, trigger: "blur"}
        ]
      },
      {
        label: "站点",
        prop: "siteName",
        align: "center",
        overHidden: true,
        addHide: true,
        editHide: true
      },
      {
        label: "站点",
        prop: "siteId",
        span: 24,
        search: true,
        type: "select",
        dicData: [],
        hide: true,
        rules: [
          {required: true, message: '请选择站点', trigger: 'blur'},
        ]
      },
      {
        label: "状态",
        prop: "status",
        align: "center",
        addHide: true,
        editHide: true,
        slot: true,
      }
    ]
  }

  openAdd() {
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
    this.getSiteSelect()
  }

  openEdit(row: IRider, index: number) {
    mallRiderDetailApi(row.id).then(e => {
      // @ts-ignore
      this.$refs.crudRef.rowEdit(e, index)
      this.getSiteSelect()
    })
  }

  getList () {
    this.tableLoading = true
    mallRiderQueryByPageApi(this.queryForm).then(e => {
      this.tableTotal = e.total
      this.tableData = e.list
      this.tableLoading = false
    })
  }

  rowSave (form: IRider, done: Function, loading: Function) {
    mallRiderCreateApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: IRider, done: Function, loading: Function) {
    mallRiderModifyApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  statusChange (id: string, status: number) {
    mallRiderModifyApi({id, status} as IRider).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  getSiteSelect () {
    mallSiteQueryByListApi().then(e => {
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
    })
  }

  created () {
    this.getList()
    this.getSiteSelect()
  }
}
