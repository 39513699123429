/**
 * @name: 骑手接口
 * @author: itmobai
 * @date: 2023-07-20 10:02
 * @description：骑手接口
 * @update: 2023-07-20 10:02
 */
import { get, postJ } from "@/request"
import { IRider, IRiderParam } from "./types"
import { IPageRes } from "@/apis/page"

/**
 * 骑手分页查询
 * @param param 查询条件
 * @returns
 */
export const mallRiderQueryByPageApi = (param: IRiderParam) => get<IPageRes<IRider[]>>("/mall/rider/queryByPage", param)
/**
 * 骑手创建
 * @param data
 * @returns
 */
export const mallRiderCreateApi = (data: IRider) => postJ("/mall/rider/create", data)
/**
 * 骑手编辑
 * @param data
 * @returns
 */
export const mallRiderModifyApi = (data: IRider) => postJ("/mall/rider/modify", data)
/**
 * 骑手详情
 * @param id
 * @returns
 */
export const mallRiderDetailApi = (id: string) => get<IRider>("mall/rider/detail/" + id)
